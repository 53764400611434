exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-damage-analysis-index-tsx": () => import("./../../../src/pages/damage-analysis/index.tsx" /* webpackChunkName: "component---src-pages-damage-analysis-index-tsx" */),
  "component---src-pages-downloads-error-index-tsx": () => import("./../../../src/pages/downloads/error/index.tsx" /* webpackChunkName: "component---src-pages-downloads-error-index-tsx" */),
  "component---src-pages-downloads-index-tsx": () => import("./../../../src/pages/downloads/index.tsx" /* webpackChunkName: "component---src-pages-downloads-index-tsx" */),
  "component---src-pages-imprint-index-tsx": () => import("./../../../src/pages/imprint/index.tsx" /* webpackChunkName: "component---src-pages-imprint-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-overview-index-tsx": () => import("./../../../src/pages/overview/index.tsx" /* webpackChunkName: "component---src-pages-overview-index-tsx" */),
  "component---src-pages-privacy-index-tsx": () => import("./../../../src/pages/privacy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-index-tsx" */),
  "component---src-pages-scan-2-bim-index-tsx": () => import("./../../../src/pages/scan-2-bim/index.tsx" /* webpackChunkName: "component---src-pages-scan-2-bim-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-templates-block-tsx": () => import("./../../../src/templates/block.tsx" /* webpackChunkName: "component---src-templates-block-tsx" */),
  "component---src-templates-dynamic-tsx": () => import("./../../../src/templates/dynamic.tsx" /* webpackChunkName: "component---src-templates-dynamic-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

